import React from "react";
import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import HomePageSlider from "./Slider.jsx";
import "../styles/Home.css";
import { Helmet } from "react-helmet"; // Import Helmet
import Ads from "./Ads.jsx";
import { useTranslation } from "react-i18next";
//import ProductsCards from "./ProductsCards.jsx";
//import News from "./News.jsx";

const Home = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet>
      <link rel="icon" href="../assets/headerlogo.png"/>
      <title>Megapharmaeg</title>
      </Helmet>
      <Header />
      <HomePageSlider />
      <div class="dark-background">
        <div class="infocolumn">{t("info.column1")}</div>
        <div class="infocolumn col2">{t("info.column2")}</div>
        <div class="infocolumn">{t("info.column3")}</div>
        <div class="infocolumn">{t("info.column4")}</div>
      </div>
      <Ads />
      {
      //<News/>
      }
      <Footer />
    </React.Fragment>
  );
};
export default Home;
