import React from 'react';
import {Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
//import Careers from './components/Careers';
import ContactUs from './components/ContactUs';
import Login from './components/Login';
import News from './components/News';
import Products from './components/Products';
//import ProductsCards from './components/ProductsCards';
import StickyButtons from './components/StickyButtons';
import ScrollToTop from './components/ScrollToTop';
import ProductDashboard from './components/ProductDashboard';
import ProductForm from './components/ProductForm';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return(
    <>
    <ScrollToTop />
      <StickyButtons />
    <Routes>
          <Route path="" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          {/*<Route path="/careers" element={<Careers />} />*/}
          <Route path="/login" element={<Login />} />
          {<Route path="/news" element={<News />} />}
          <Route path="/products/:type" element={<Products />} />
          <Route path="/admin" element={<ProtectedRoute element={<ProductDashboard />} />} />
          <Route path="/admin/products" element={<ProtectedRoute element={<ProductDashboard />} />} />
        <Route path="/admin/add-product" element={<ProtectedRoute element={<ProductForm />} />} />
        <Route path="/admin/edit-product/:id" element={<ProtectedRoute element={<ProductForm />} />} />
        </Routes>
  </>
  )
};

export default App;
