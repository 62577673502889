import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import '../styles/Login.css'; // Import your CSS file for styling

const Login = () => {
    const [passwordVisible] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
      // If in production, use the deployed Heroku or live domain URL; otherwise, use the local backend.
const BASE_URL = process.env.NODE_ENV === 'production' 
? ''  // Empty string, as frontend and backend are served from the same origin in production
: 'http://localhost:5000'; // Local backend URL in development

    /*const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };*/

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post(`${BASE_URL}/api/login`, { username, password });
            // Store the JWT token in local storage
            localStorage.setItem('token', response.data.token);
            // Redirect to the admin page
            navigate('/admin/products');
        } catch (error) {
            // Check if the error response exists and is a validation error
            if (error.response && error.response.status === 400) {
                alert('Login credentials are not correct. Please try again.'); // Show alert for invalid credentials
            } else {
                alert('An error occurred. Please try again later.'); // General error message
            }
        }
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input 
                            type="text"  // Change from "username" to "text"
                            id="username" 
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)} 
                            required 
                            className='logininput'
                        />
                    </div>
                    <div className="form-group password-group">
                        <label htmlFor="password">Password</label>
                        <input 
                            type={passwordVisible ? 'text' : 'password'} 
                            id="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                            className='logininput'
                        />
                        {/* Uncomment to enable password visibility toggle
                        <button 
                            type="button" 
                            className="toggle-password" 
                            onClick={togglePasswordVisibility}
                        >
                            {passwordVisible ? 'Hide' : 'Show'}
                        </button>
                        */}
                    </div>
                    <button type="submit" className="login-button">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
