import React from "react";
//import Slider from "react-slick";
import "../styles/News.css"; // Ensure this path is correct
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/*import img1 from "../assets/ourmission.jpg";
import img2 from "../assets/ourstory.jpg";
import img3 from "../assets/ourproducts.jpg";
import video1 from "../assets/vid2.mp4";*/
//import { FaFacebookF } from "react-icons/fa";

const NewsCards = () => {
  const newsItems = [
    {
      id: 1,
      heading: "Company Launches New Product Line",
      report:
        "Our company is excited to announce the launch of a new product line that includes a variety of innovative gadgets designed to improve everyday life. Stay tuned for more updates and detailed product descriptions.",
      date: "August 25, 2024",
      media: [
        {
          type: "iframe",
          html: "<iframe src='https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02t6qqbW644Hje6kqJJTVogrcivhZx3auyujCmrX7oiH7AJhnzu8fGUtMrjyWRVoewl%26id%3D100063941944482&show_text=true&width=500' width='500' height='722' style='border:none;overflow:hidden' scrolling='no' frameborder='0' allowfullscreen='true' allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>",
        },
      ],
      fblink: "https://www.facebook.com/share/p/6xT8XiwhW3zxhYMo/"
    },
    {
      id: 2,
      heading: "Quarterly Earnings Report Released",
      report:
        "The company has released its quarterly earnings report, showing significant growth in revenue and profit. This success is attributed to the strong performance of our new product launches and increased market share.",
      date: "August 18, 2024",
      media: [],
      //fblink: ""
    },
    {
      id: 3,
      heading: "Upcoming Tech Conference Participation",
      report:
        "We are proud to announce our participation in the upcoming Tech Innovators Conference. Our team will be showcasing the latest developments and engaging with industry leaders to discuss future trends.",
      date: "August 10, 2024",
      media: [],
      //fblink: ""
    },
  ];

  /*const PrevArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    />
  );

  const NextArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    />
  );*/

  /*const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };*/

  return (
    <div className="news-container">
      {newsItems.map((news) => (
        <div key={news.id} className="news-card">
          <h2 className="news-heading">{news.heading}</h2>
          <p className="news-report">{news.report}</p>
          <p className="news-date">{news.date}</p>
          {news.fblink && <div className="news-facebook-logo">
            <a
              href={news.fblink} // Replace with your Facebook post URL
              target="_blank"
              rel="noopener noreferrer"
              aria-label="View on Facebook"
              style={{textDecoration: "none", color: "blue"}}
            >
              See post on Facebook
            </a>
          </div>}
          
          {/*
          <div className="news-media-item" style={{display: "flex", justifyContent: "center"}}>
            {news.media.map((item, index) => (
              <div
                key={index}
                dangerouslySetInnerHTML={{ __html: item.html }}
              />
            ))}
          </div>*/}
        </div>
      ))}
    </div>
  );
};

export default NewsCards;
