import React from "react";
import { FaFacebookF, FaWhatsapp, FaPhone, FaHome } from "react-icons/fa";
import { useTranslation, Trans } from "react-i18next";
import "../styles/Footer.css";
import logo from "../assets/logo1.png";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer-container">
      {/* First Row: Social Media Links */}
      <div className="social-row">
        <a
          href="https://www.facebook.com/profile.php?id=100063941944482"
          className="icon"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookF />
        </a>
        <a
          href={`https://wa.me/201279841733`}
          className="icon"
          target="_blank"
          rel="noreferrer"
        >
          <FaWhatsapp />
        </a>
      </div>

      {/* Second Row: Company Info and Contact */}
      <div className="info-row">
        {/* Column 1: Company Info */}
        <div className="column">
          <img src={logo} alt="Company Logo" className="footer-logo" />
          {/*
            <h4>{t("footer.companyName")}</h4>
            */}
          <p>{t("footer.description")}</p>
        </div>

        {/* Column 2: Contact Info */}
        <div className="column">
          <h4>{t("footer.contact")}</h4>
          <a
            href="https://maps.app.goo.gl/L6ohJmGkDC3sHMAV8"
            className="icon"
            target="_blank"
            rel="noreferrer"
          >
            <FaHome />
          </a>
          <p>{t("footer.address")}</p>
          <br />
          <a
            href={`tel:+201279841733`}
            className="icon"
            target="_blank"
            rel="noreferrer"
          >
            <FaPhone />
          </a>
          <br />
          <div style={{ display: "flex", margin: "10px", flexWrap: "wrap", gap: "10px", textAlign: "center", justifyContent: "center" }}>
            <div>
              <a style={{ color: "blue", textDecoration: "none", margin: "5px" }} href={`tel:+20 1279841733`}>
                +20 1279841733
              </a>
              <br />
              <a style={{ color: "blue", textDecoration: "none", margin: "5px" }} href={`tel:+20 1018333767`}>
                +20 1018333767
              </a>
            </div>
            <div>
              <a style={{ color: "blue", textDecoration: "none", margin: "5px" }} href={`tel:+03 3302279`}>
                +03 3302279
              </a>
              <br />
              <a style={{ color: "blue", textDecoration: "none", margin: "5px" }} href={`tel:+03 3306619`}>
                +03 3306619
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Third Row: Copyright */}
      <div className="copyright-row">
        <p>
          <Trans i18nKey="footer.copyright">
            <a
              href="https://github.com/YoussefKhafaga"
              target="_blank"
              rel="noreferrer"
              style={{ color: "blue", textDecoration: "none"}}
            >
              
            </a>
          </Trans>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
