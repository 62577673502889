import React from 'react';
//import Header from './Header';
//import Footer from './Footer';
import NewsCards from './NewsCards.jsx';
//import img1 from "../assets/news.jpg";

const News = () => {
    return (
        <React.Fragment>
            {
                /*
            <Header/>
            <div style={{ display: "flex", justifyContent: "center", margin: "10px"}}>
            <h1>Our News</h1>
            </div>
            <div style={{ display: "flex", justifyContent: "center", margin: "20px"}}>
                <img src={img1} style={{ width: "90%", height: "90%" }} alt="News" />
            </div>
            <Footer/>
            */}
            <NewsCards />
            
        </React.Fragment>
    );
};

export default News;
