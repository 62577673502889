import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ProductsCards from "./ProductsCards";
import { Helmet } from "react-helmet"; // Import Helmet

const Products = () => {
  const { type } = useParams(); // Get the product type from the URL
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // If in production, use the deployed Heroku or live domain URL; otherwise, use the local backend.
    const BASE_URL =
      process.env.NODE_ENV === "production"
        ? "" // Empty string, as frontend and backend are served from the same origin in production
        : "http://localhost:5000"; // Local backend URL in development
    // Fetch products based on the selected type
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/products/${type}`); // Use the URL parameter here
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [type]);

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href="../assets/headerlogo.png" />
        <title>Megapharmaeg | Products</title>
      </Helmet>
      <Header />
      {/*
                <h2 style={{ display: "flex", margin: "10px", justifyContent: "center", color: "#12A89D" }}>Products - {type}</h2>
*/}
      <ProductsCards products={[...products].reverse()} />
      <Footer />
    </React.Fragment>
  );
};

export default Products;
