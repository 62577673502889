import React from "react";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet"; // Import Helmet
import "../styles/About.css";
import ourmission from "../assets/ourmission123.jpg";
import ourproducts from "../assets/ourprod.jpeg";
import ourstory from "../assets/ourstory.jpg";
import Header from "./Header";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t, i18n } = useTranslation();
  const [ref1, inView1] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: false, threshold: 0.1 });

  const textAlignmentClass =
    i18n.language === "ar" ? "text-right" : "text-left";
    
  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href="../assets/headerlogo.png" />
        <title>Megapharmaeg | About us</title>
      </Helmet>
      <Header />
      <div className="about-us">
        {/* First section - Our Story */}
        <div className="about-section">
          <div
            className={`about-image ${
              inView1 ? "slide-in-right" : "slide-out-right"
            }`}
            ref={ref1}
          >
            <img src={ourstory} style={{ width: "80%" }} alt="Our Story" />
          </div>

          <div
            className={`about-text ${textAlignmentClass} ${
              inView1 ? "slide-in-left" : "slide-out-left"
            }`}
          >
            <h4>{t("About.storyheader")}</h4>
            <p style={{fontSize: "18px"}}>{t("About.story")}</p>
          </div>
        </div>

        {/* Second section - Our Mission */}
        <div className="about-section">
          <div
            className={`about-image ${
              inView2 ? "slide-in-right" : "slide-out-right"
            }`}
            ref={ref2}
          >
            <img src={ourmission} style={{ width: "80%" }} alt="Our Mission" />
          </div>

          <div
            className={`about-text ${textAlignmentClass} ${
              inView2 ? "slide-in-left" : "slide-out-left"
            }`}
          >
            <h4>{t("About.missionheader")}</h4>
            <p style={{fontSize: "18px"}}>{t("About.mission")}</p>
          </div>
        </div>

        {/* Third section - Our Products */}
        <div className="about-section">
          <div
            className={`about-image ${
              inView3 ? "slide-in-right" : "slide-out-right"
            }`}
            ref={ref3}
          >
            <img
              src={ourproducts}
              style={{ width: "80%" }}
              alt="Our Products"
            />
          </div>

          <div
            className={`about-text ${textAlignmentClass} ${
              inView3 ? "slide-in-left" : "slide-out-left"
            }`}
          >
            <h4>{t("About.productsheader")}</h4>
            <p style={{fontSize: "18px"}}>{t("About.products")}</p>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default About;
