import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/Header.css";
import Home from "./Home";
import About from "./About";
import ContactUs from "./ContactUs";
import logo from "../assets/headerlogo.png";
import { useTranslation } from "react-i18next";
import "../App.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  // Determine text alignment based on the current language
  const textAlignmentClass =
    i18n.language === "ar" ? "text-right" : "text-left";

  return (
    <header>
      <nav className={`navbar ${textAlignmentClass}`}>
        <div className="logo">
          <img
            src={logo}
            alt="Logo"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
        </div>

        {/* Hamburger Icon */}
        <div
          className={`hamburger ${isOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>

        {/* Navigation Links for larger screens */}
        <ul className={`nav-links ${isOpen ? "show" : ""}`}>
          <li onClick={closeMenu}>
            <Link to="/" element={<Home />}>
              {t("header.home")}
            </Link>
          </li>
          <li className="dropdown">
            <li className="dropbtn">
              {t("header.products")} <i className="fa fa-caret-down"></i>
            </li>
            <div className="dropdown-content">
              <Link to="/products/ointments" onClick={closeMenu}>
                {t("products.ointments")}
              </Link>

              {/* Poultry Sub-Menu */}
              <div className="poultry-dropdown">
                <Link
                  className="dropbtn-poultry"
                  style={{ marginBottom: "5px" }}
                >
                  {t("products.poultry")} <i className="fa fa-caret-left"></i>
                </Link>
                <div className={i18n.language === "en" ? "right-dropdown-content" : "left-dropdown-content"}>
                  <Link to="/products/Immune-Boosters-and-Antivirals" onClick={closeMenu}>
                    {t("products.poultry.subcategory1")}
                  </Link>
                  <Link to="/products/Amino-Acids-Salts-and-Vitamins" onClick={closeMenu}>
                    {t("products.poultry.subcategory2")}
                  </Link>
                  <Link to="/products/Coccidiosis-Toxins-Detoxifiers-and-Bronchodilators" onClick={closeMenu}>
                    {t("products.poultry.subcategory3")}
                  </Link>
                </div>
              </div>
              <Link to="/products/premixes" onClick={closeMenu}>
                {t("products.premix")}
              </Link>
              <Link to="/products/large" onClick={closeMenu}>
                {t("products.large")}
              </Link>
              <Link to="/products/disinfectants" onClick={closeMenu}>
                {t("products.disinfectants")}
              </Link>
              <Link to="/products/chemicals" onClick={closeMenu}>
                {t("products.chemicals")}
              </Link>
            </div>
          </li>

          <li onClick={closeMenu}>
            <Link to="/about" element={<About />}>
              {t("header.about")}
            </Link>
          </li>
          <li onClick={closeMenu}>
            <Link to="/contact" element={<ContactUs />}>
              {t("header.contact")}
            </Link>
          </li>
        </ul>

        {/* Language Switcher for small screens */}
        {isOpen && (
          <div className="language-switcher-small">
            {i18n.language === "ar" ? (
              <button
                onClick={() => {
                  changeLanguage("en");
                  closeMenu();
                }}
              >
                English
              </button>
            ) : (
              <button
                onClick={() => {
                  changeLanguage("ar");
                  closeMenu();
                }}
              >
                العربية
              </button>
            )}
          </div>
        )}

        {/* Language Switcher for larger screens */}
        <div className="language-switcher">
          {i18n.language === "ar" ? (
            <button
              onClick={() => {
                changeLanguage("en");
                closeMenu();
              }}
            >
              English
            </button>
          ) : (
            <button
              onClick={() => {
                changeLanguage("ar");
                closeMenu();
              }}
            >
              العربية
            </button>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
