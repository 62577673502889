import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import "../styles/Slider.css"; // Import your custom styles
import { useTranslation } from 'react-i18next';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../assets/ourmission.jpg";
import img2 from "../assets/coverphoto1.jpeg";
import img3 from "../assets/chicken.jpg";

const PrevArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
    onClick={onClick}
  />
);

const NextArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
    onClick={onClick}
  />
);

const HomePageSlider = () => {
  const { t } = useTranslation();
  const sliderRef = useRef(null); // Create a ref for the slider
  const videoRef = useRef(null); // Create a ref for the video

  const handlePlay = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause(); // Pause the slider when the video plays
    }
  };

  const handlePause = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay(); // Resume the slider when the video pauses
    }
  };

  const handleEnded = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Reset video to start
      videoRef.current.pause(); // Ensure video is paused
    }
    handlePause(); // Resume the slider autoplay
  };

  useEffect(() => {
    // Clean up function to ensure video is reset when component unmounts
    return () => {
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.pause();
      }
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    pauseOnHover: false, // Prevent autoplay from pausing on hover
    ref: sliderRef
  };

  const slides = [
    { type: 'image', src: img1, text: t('slider.sliderText1') },
    { type: 'image', src: img2, text: t('slider.sliderText2') },
    { type: 'image', src: img3, text: t('slider.sliderText3') },
  ];

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slick-slide-content">
            {slide.type === 'video' ? (
              <video
                ref={videoRef}
                src={slide.src}
                controls
                onPlay={handlePlay}
                onPause={handlePause}
                onEnded={handleEnded}
              />
            ) : (
              <img src={slide.src} alt={`Slide ${index}`} />
            )}
            <p className="slide-text">{slide.text}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomePageSlider;
