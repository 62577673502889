import React from 'react';
import { FaWhatsapp, FaPhone } from 'react-icons/fa';
import '../styles/StickyButtons.css'; // Import the CSS for styling

const StickyButtons = () => {
  return (
    <div className="sticky-buttons">
      {/* WhatsApp Button */}
      <a 
        href="https://wa.me/201279841733" 
        className="sticky-button whatsapp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp />
      </a>

      {/* Phone Button */}
      <a 
        href="tel:201018333767"
        className="sticky-button phone"
      >
        <FaPhone />
      </a>
    </div>
  );
};

export default StickyButtons;
