import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import "../styles/ProductDashboard.css";

const ProductDashboard = () => {
  // If in production, use the deployed Heroku or live domain URL; otherwise, use the local backend.
  const BASE_URL = process.env.NODE_ENV === 'production' 
    ? ''  // Empty string, as frontend and backend are served from the same origin in production
    : 'http://localhost:5000'; // Local backend URL in development

  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for the search term
  const navigate = useNavigate();

  // Function to fetch products
  const fetchProducts = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/api/products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProducts(response.data);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []); // Add empty dependency array to fetch products only once

  const handleEdit = (id) => {
    navigate(`/admin/edit-product/${id}`); // Navigate to the product form with the product ID
  };

  const handleDelete = (id, productName) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete the product "${productName}"?`);

    if (!confirmDelete) {
      return; // Exit if the user cancels the deletion
    }

    if (!id) {
      console.error('Product ID is undefined');
      return; // Exit if ID is undefined
    }

    const token = localStorage.getItem("token"); // Retrieve the JWT token
    
    axios.delete(`${BASE_URL}/api/admin/products/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the headers
      },
    })
    .then(() => {
      // Update the state to remove the deleted product immediately
      setProducts(prevProducts => prevProducts.filter(product => product.id !== id));
    })
    .catch(error => {
      console.error('Error deleting product:', error); // Log error details
      if (error.response && error.response.status === 401) {
        // Redirect to login if unauthorized
        navigate("/login");
      }
    });
  };

  const handleAddProduct = () => {
    navigate("/admin/add-product");
  };

  // Filter products based on the search term
  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="product-dashboard">
      <h1>Product Dashboard</h1>
      <input
        type="text"
        placeholder="Search by name..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)} // Update search term
        className="dash-search-input"
      />
      <br />
      <button onClick={handleAddProduct}>Add Product</button>
      <table className="product-table">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Description</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.length === 0 ? (
            <tr>
              <td colSpan="4">No products available</td>
            </tr>
          ) : (
            filteredProducts.map((product) => (
              <tr key={product.id}> {/* Use id for key */}
                <td>{product.name}</td>
                <td>{product.description}</td>
                <td>{product.productType.join(", ")}</td> {/* Join product types */}
                <td>
                  <FaEdit
                    onClick={() => handleEdit(product.id)} // Use id for edit
                    className="edit-icon"
                  />
                  <FaTrash
                    onClick={() => handleDelete(product.id, product.name)} // Use id for delete
                    className="delete-icon"
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProductDashboard;
