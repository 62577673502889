import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { FaFacebookF, FaWhatsapp, FaPhone, FaHome } from "react-icons/fa";
import contactusimg from "../assets/contact-us.jpg";
import "../styles/ContactUs.css";
import { Helmet } from "react-helmet"; // Import Helmet
import { useTranslation } from 'react-i18next';


const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
            <Helmet>
      <link rel="icon" href="../assets/headerlogo.png"/>
      <title>Megapharmaeg | Contact us</title>
      </Helmet>
      <Header />
      <div
        style={{ display: "flex", margin: "10px", justifyContent: "center" }}
      >
        <img
          src={contactusimg}
          alt="Contact Us"
          style={{ width: "80%", margin: "20px" }}
        />
      </div>
      <div
        className="info-row-contacts"
        style={{
          width: "80%",
          height: "550px",
          margin: "50px auto",
          boxSizing: "border-box",
          display: "flex",
          gap: "100px",
          justifyContent: "center", // Corrected property name
        }}
      >
        <div className="column-contacts">
          <div style={{ justifyContent: "center" }}>
            <h4>{t("contactus.location")}</h4>
          </div>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2585.6951482786585!2d29.996170674313966!3d31.219307274353174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f5db1842a0b1d1%3A0x540cbd050a92a9eb!2sEzbet%20El-Bahr%20Rd%2C%20Al%20Mohagerin%2C%20Third%20Al%20Montazah%2C%20Alexandria%20Governorate!5e1!3m2!1sen!2seg!4v1724498985554!5m2!1sen!2seg"
              width="600"
              height="450"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="addres location"
            ></iframe>
          </div>
          <button
            style={{
              backgroundColor: "#12A89D",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              padding: "10px 20px",
              cursor: "pointer",
              fontSize: "16px",
            }}
            onClick={() =>
              window.open("https://maps.app.goo.gl/bpmpbLcfFa9mz1oC9", "_blank")
            }
          >
            {t("contactus.maploc")}
          </button>
        </div>

        {/* Column 2: Contact Info */}
        <div className="column-contacts-mob">
          <h4>{t("contactus.contact")}</h4>
          <div style={{ marginBottom: "10px" }}>
            <a
              href="https://www.facebook.com/profile.php?id=100063941944482"
              className="icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
            <a
              href={`https://wa.me/201279841733`}
              className="icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp />
            </a>
          </div>
          <br />
          <div style={{ display: "flex", alignItems: "center" }}>
            <a
              href="https://maps.app.goo.gl/L6ohJmGkDC3sHMAV8"
              className="icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaHome />
            </a>
            <p style={{ marginTop: 0 }}>
              {t("contactus.address")}
            </p>
          </div>
          <br />
          <div style={{ display: "flex", alignItems: "center" }}>
            <a
              href={`tel:201279841733`}
              className="icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaPhone />
            </a>
            <div style={{ display: "flex", margin: "10px", flexWrap: "wrap", gap: "10px", textAlign: "center", justifyContent: "center" }}>
            <div>
              <a style={{ color: "blue", textDecoration: "none", margin: "5px" }} href={`tel:+20 1279841733`}>
                +20 1279841733
              </a>
              <br />
              <a style={{ color: "blue", textDecoration: "none", margin: "5px" }} href={`tel:+20 1018333767`}>
                +20 1018333767
              </a>
            </div>
            <div>
              <a style={{ color: "blue", textDecoration: "none", margin: "5px" }} href={`tel:+03 3302279`}>
                +03 3302279
              </a>
              <br />
              <a style={{ color: "blue", textDecoration: "none", margin: "5px" }} href={`tel:+03 3306619`}>
                +03 3306619
              </a>
            </div>
          </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default ContactUs;
