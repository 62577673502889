import React, { Fragment, useState, useEffect } from "react";
import "../styles/Products.css";
import logo from "../assets/logo1.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ProductsCards = ({ products }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  // Wait for styles and content to be fully loaded
  useEffect(() => {
    const checkStylesLoaded = () => {
      const areStylesLoaded = Array.from(document.styleSheets).every(
        (sheet) => sheet.cssRules && sheet.cssRules.length > 0
      );
      if (areStylesLoaded) setIsLoaded(true);
    };

    const styleLoadInterval = setInterval(checkStylesLoaded, 100);
    const timeoutFallback = setTimeout(() => setIsLoaded(true), 3000);

    return () => {
      clearInterval(styleLoadInterval);
      clearTimeout(timeoutFallback);
    };
  }, []);

  // Filter products based on the search term
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!isLoaded) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        Loading...
      </div>
    );
  }

  return (
    <Fragment>
      <div className="search-input-container">
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term
          className="search-input"
        />
      </div>

      <div className="products-container">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))
        ) : (
          <p>No products found</p>
        )}
      </div>
    </Fragment>
  );
};

const ProductCard = ({ product }) => {
  const BASE_URL =
    process.env.NODE_ENV === "production" ? "" : "http://localhost:5000"; // Local backend URL in development

  const { t } = useTranslation();
  //product.sizes = product.sizes.slice().reverse(); // Ensure sizes display in reverse order
  const [selectedSize, setSelectedSize] = useState(
    product.sizes[product.sizes.length - 1]
  );
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Handle size selection and reset image index
  const handleSizeClick = (size) => {
    setSelectedSize(size);
    setCurrentImageIndex(0);
  };

  const handleNextImage = () => {
    if (product.imagesBySize[selectedSize]) {
      setCurrentImageIndex(
        (prevIndex) =>
          (prevIndex + 1) % product.imagesBySize[selectedSize].length
      );
    }
  };

  const handlePreviousImage = () => {
    if (product.imagesBySize[selectedSize]) {
      setCurrentImageIndex(
        (prevIndex) =>
          (prevIndex - 1 + product.imagesBySize[selectedSize].length) %
          product.imagesBySize[selectedSize].length
      );
    }
  };

  // Format formation text to fit neatly
  const formatFormation = (formation) => {
    const components = formation.split(",");
    const chunks = [];
    for (let i = 0; i < components.length; i += 8) {
      chunks.push(components.slice(i, i + 8).join(" "));
    }
    return chunks;
  };

  // Get the current image based on size and index
  const currentImages = product.imagesBySize[selectedSize] || [];
  const currentImage = currentImages[currentImageIndex]
    ? `${BASE_URL}/uploads/${currentImages[currentImageIndex]}`
    : logo;

  return (
    <Fragment>
      <div className="product-card">
        <div className="image-container">
          <img
            src={currentImage}
            alt={`${product.name} - ${selectedSize}`}
            onError={(e) => (e.target.src = logo)} // Fallback for broken image URLs
          />
          {currentImages.length > 1 && (
            <>
              <button
                className="arrow left"
                onClick={handlePreviousImage}
                disabled={currentImages.length <= 1}
              >
                <FaChevronLeft />
              </button>
              <button
                className="arrow right"
                onClick={handleNextImage}
                disabled={currentImages.length <= 1}
              >
                <FaChevronRight />
              </button>
            </>
          )}
        </div>
        <h3>{product.name}</h3>
        <p>{product.description}</p>
        <p>
          <strong>{t("card.formation")}</strong>
          <br />
          {formatFormation(product.formation).map((chunk, index) => (
            <Fragment key={index}>
              {chunk}
              <br />
            </Fragment>
          ))}
        </p>
        <p>
          <strong>{t("card.usage")}</strong> {product.usage}
        </p>
        <p>
          <strong>{t("card.dose")}</strong> {product.dose}
        </p>
        <p>
          <strong>{t("card.size")}</strong>
          <div className="sizes-buttons">
            {product.sizes
              .slice()
              .reverse()
              .map((size) => (
                <button
                  key={size}
                  className={selectedSize === size ? "selected" : ""}
                  onClick={() => handleSizeClick(size)}
                >
                  {size}
                </button>
              ))}
          </div>
        </p>
      </div>
    </Fragment>
  );
};

export default ProductsCards;
