import React, { useState, useEffect } from "react";
import "../styles/productForm.css";
import axios from "axios";
import { useParams } from "react-router-dom";

const ProductForm = () => {
  const { id } = useParams(); // Get product ID from URL (for editing)
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    dose: "",
    formation: "",
    usage: "",
    sizes: [],
    images: {},
    productType: [],
  });
  const [errors, setErrors] = useState({});

  const sizeOptions = [
    "100cm",
    "250cm",
    "500cm",
    "100ml",
    "250ml",
    "500ml",
    "100g",
    "125g",
    "800g",
    "1L",
    "4L",
    "5L",
    "10L",
    "20L",
    "1KG",
    "3KG",
    "5KG",
    "20KG",
    "30KG",
  ]; // Example sizes
  const productTypes = ["poultry","اضافات وبريمكسات", "روافع المناعة والمضادات الفيروسية", "الاحماض الامينية والاملاح والفيتامينات" ," الكوكسيديا والسموم والغسيل وموسع الشعب"  , "الكيماويات", "disinfectants", "large", "ointments"]; // Example product types
  useEffect(() => {
    // If in production, use the deployed Heroku or live domain URL; otherwise, use the local backend.
    const BASE_URL =
      process.env.NODE_ENV === "production"
        ? "" // Empty string, as frontend and backend are served from the same origin in production
        : "http://localhost:5000"; // Local backend URL in development
    if (id) {
      const token = localStorage.getItem("token"); // Get token from local storage

      axios
        .get(`${BASE_URL}/api/admin/products/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Set token in headers with backticks
          },
        })

        .then((response) => {
          const product = response.data;
          setFormData({
            name: product.name,
            description: product.description,
            dose: product.dose,
            formation: product.formation,
            usage: product.usage,
            sizes: product.sizes,
            images: product.imagesBySize, // Populate with existing images
            productType: product.productType,
          });
        })
        .catch((error) => console.error("Error fetching product:", error));
    }
  }, [id]);

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Product name is required";
    if (formData.sizes.length === 0)
      newErrors.sizes = "At least one size must be selected";
    if (formData.productType.length === 0)
      newErrors.productType = "At least one product type must be selected";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSizeChange = (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      sizes: prevData.sizes.includes(value)
        ? prevData.sizes.filter((size) => size !== value)
        : [...prevData.sizes, value],
    }));
  };

  const handleImageChange = (size, e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        images: {
          ...prevData.images,
          [size]: files, // Store the files directly for that size
        },
      }));
    }
  };

  const handleProductTypeChange = (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      productType: prevData.productType.includes(value)
        ? prevData.productType.filter((type) => type !== value)
        : [...prevData.productType, value],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("description", formData.description);
        formDataToSend.append("dose", formData.dose);
        formDataToSend.append("formation", formData.formation);
        formDataToSend.append("usage", formData.usage);
        formDataToSend.append("sizes", JSON.stringify(formData.sizes));
        formDataToSend.append(
          "productType",
          JSON.stringify(formData.productType)
        );

        formData.sizes.forEach((size) => {
          if (formData.images[size]) {
            formData.images[size].forEach((image) => {
              formDataToSend.append(`images[${size}]`, image); // Append each file with proper syntax
            });
          }
        });

        // If in production, use the deployed Heroku or live domain URL; otherwise, use the local backend.
        const BASE_URL =
          process.env.NODE_ENV === "production"
            ? "" // Empty string, as frontend and backend are served from the same origin in production
            : "http://localhost:5000"; // Local backend URL in development
        const url = id
          ? `${BASE_URL}/api/admin/products/${id}`
          : `${BASE_URL}/api/admin/add-products`;

        const method = id ? "put" : "post";
        const token = localStorage.getItem("token");

        await axios({
          method,
          url,
          data: formDataToSend,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        window.alert(
          id ? "Product updated successfully!" : "Product added successfully!"
        );

        // Reset form after submission (optional)
        setFormData({
          name: "",
          description: "",
          dose: "",
          formation: "",
          usage: "",
          sizes: [],
          images: {},
          productType: [],
        });
      } catch (error) {
        console.error("Error submitting product:", error);
      }
    }
  };

  return (
    <div className="productformbody">
      <form onSubmit={handleSubmit}>
        {/* Form fields */}
        <div>
          <label>Product Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
          {errors.name && <span>{errors.name}</span>}
        </div>
        <div>
          <label>Description</label>
          <input
            type="text"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
          />
          {errors.description && <span>{errors.description}</span>}
        </div>
        {/* Additional fields */}
        <div>
          <label>Dose</label>
          <input
            type="text"
            name="dose"
            value={formData.dose}
            onChange={handleInputChange}
          />
          {errors.dose && <span>{errors.dose}</span>}
        </div>
        <div>
          <label>Formation</label>
          <textarea
            name="formation"
            value={formData.formation}
            onChange={handleInputChange}
          />
          {errors.formation && <span>{errors.formation}</span>}
        </div>
        <div>
          <label>Usage</label>
          <input
            type="text"
            name="usage"
            value={formData.usage}
            onChange={handleInputChange}
          />
          {errors.usage && <span>{errors.usage}</span>}
        </div>
        {/* Sizes */}
        <hr />
        <div>
          <label>Sizes</label>
          {sizeOptions.map((size) => (
            <div key={size}>
              <label>{size}</label>
              <input
                type="checkbox"
                value={size}
                checked={formData.sizes.includes(size)}
                onChange={handleSizeChange}
              />
            </div>
          ))}
          {errors.sizes && <span>{errors.sizes}</span>}
        </div>
        {/* Images */}
        <hr />
        <div>
          <label>Images for Sizes</label>
          {formData.sizes.map((size) => (
            <div key={size}>
              <label>{size}</label>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={(e) => handleImageChange(size, e)}
              />
              {/* Show selected images */}
              {formData.images[size] && (
                <span>{formData.images[size].length} files selected</span>
              )}
            </div>
          ))}
        </div>

        {/* Product Types */}
        <hr />
        <div>
          <label>Product Type</label>
          {productTypes.map((type) => (
            <div key={type}>
              <label>{type}</label>
              <input
                type="checkbox"
                value={type}
                checked={formData.productType.includes(type)}
                onChange={handleProductTypeChange}
              />
            </div>
          ))}
          {errors.productType && <span>{errors.productType}</span>}
        </div>
        <button type="submit">{id ? "Update Product" : "Submit"}</button>
      </form>
    </div>
  );
};

export default ProductForm;
