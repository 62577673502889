import React from "react";
import img4 from "../assets/image1.jpeg";
import img5 from "../assets/image2.jpeg";
import img6 from "../assets/image3.jpeg";
import img7 from "../assets/image4.jpeg";
import "../styles/Ads.css"; // Import the CSS file for styles

const Ads = () => {
  return (
    <div className="ads-body">
      <div className="ads-container">
        <div className="ads-item">
          <img src={img4} alt="Ad 1" />
        </div>
        <div className="ads-item">
          <img src={img5} alt="Ad 2" />
        </div>
        <div className="ads-item">
          <img src={img6} alt="Ad 3" />
        </div>
        <div className="ads-item">
          <img src={img7} alt="Ad 4" />
        </div>
      </div>
    </div>
  );
};

export default Ads;
